import React from 'react'

import Header from "../components/Home/Header"
import AboutUs from '../components/Home/AboutUs';
import HowToUse from "../components/Home/HowToUse";
import CoreFeatures from "../components/Home/CoreFeatures"
import Compare from "../components/Home/Compare"
import LastCTA from "../components/Home/LastCTA"
import Footer from "../components/Home/Footer"

export const Home = () => {
return (
    <div>
      {/* <Navbar /> */}
      <Header />
      <AboutUs />
      <HowToUse />
      <CoreFeatures />
      <Compare />
      <LastCTA />
      <Footer />
    </div>
  )
}

export default Home