import React from "react";
import P from "../Text/P";

export const ImageRight = (props) => {
  return (
    <div>
      <div>
        <div className="py-10 grid md:grid-cols-2 grid-cols-1 gap-x-10 md:gap-y-0 gap-y-10 content-center items-center">
          <div className="text-left md:order-first order-last">
            <h2 className="text-2xl pb-4 md:text-4xl font-bold">
              {props.title}
            </h2>
            <P text={props.text} />
          </div>
          <div className="flex justify-center items-center">
            <img src={props.img} alt="" className="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageRight;
