import React from 'react'

// Components
import P from "../Text/P";
import ImageLeft from "../Features/ImageLeft";
import ImageRight from "../Features/ImageRight";

// Image
import Tracking from "../../images/features/tracking-compressed.gif"
import ChooseTransport from "../../images/features/choose transport mode-compressed.gif";
import Badges from "../../images/features/badges.webp";
import Summary from "../../images/features/share.webp"
import TripSummary from "../../images/features/trip summary.webp"

export const CoreFeatures = () => {
  return (
    <div>
      <section className="" id="features">
        <div className="container mx-auto px-4 md:px-10 lg:px-32 flex flex-col justify-center items-center content-center py-20">
          {/* Title */}
          <div className="pb-6">
            <h2 className="text-2xl pb-2 md:text-4xl font-bold text-center">
              Our Core Features 🔥
            </h2>
            <P text="Our application features are designed to save you time and provide the best user experience." />
          </div>

          <ImageLeft
            img={Tracking}
            title="Track Your Carbon Emission Real-Time 🚌💨"
            text="With Tracco you can track the impact of your everyday carbon emissions. It’s an easy way to see how much carbon you consume, while also tracking and reducing it!"
          />

          <ImageRight
            img={ChooseTransport}
            title="Choose Your Transportation Mode 🚗"
            text="Pick the transportation mode that makes your ride the most efficient. Choosing wisely is what saves the most carbon and make the right choice to reduce your impact on our environment."
          />

          <ImageLeft
            img={TripSummary}
            title="View Your Tracking Summary ✨"
            text="View your trip summary after finishing tracking, we’ll detect automatically if the trip that you’ve done are emitted already good or can still be improved to be even better."
          />

          <ImageRight
            img={Summary}
            title="Share Your Carbon Emission Journey to The Community 🌏"
            text="Be inspired to take action and take steps towards reducing your carbon footprint. Share your journey with people aroun you and help us spread awareness to the world!"
          />

          <ImageLeft
            img={Badges}
            title="Special Badges Only for You 💎"
            text="You can take challenges to reduce your carbon emission and get precious badges as your reward. This feature will release soon! So make sure you sign up to claim your badge earlier."
          />
        </div>
      </section>
    </div>
  )
}

export default CoreFeatures