/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import { useEffect, useState } from "react";
import Comparison from "../../images/comparison-compressed.gif";
import P from "../Text/P";
// import { ComparisonSlider } from "react-comparison-slider";

// import Before from "../../images/comparison/before.png";
// import After from "../../images/comparison/after.png";

// import { BiMoveHorizontal } from "react-icons/bi";

export const Compare = () => {
  // const [value, setValue] = useState(50);

  return (
    <div>
      <section className="bg-[#F5F6F6]" id="compare">
        <div className="container mx-auto px-4 md:px-10 lg:px-32 flex flex-col justify-center items-center content-center py-20">
          {/* Title */}
          <div className="pb-6">
            <h2 className="text-2xl pb-2 md:text-4xl font-bold text-center">
              Compare Your Emission
            </h2>
            <P text="We make your commute smarter, greener, and more efficient by providing the best transportation that has low emissions & cost." />
          </div>

          {/* Comparison Slider */}
          <div className="w-full flex items-center justify-center">
            <img src={Comparison} alt="Comparison before and after using public transportation" className="w-full" />
            {/* <ComparisonSlider
              value={value}
              onValueChange={setValue}
              itemOne={
                <img src={Before} alt="Current Transportation" className="w-full h-full object-cover"/>
              }
              itemTwo={
                <img src={After} alt="If Use Public Transportation" className="w-full h-full object-cover" />
              }
              handleAfter={
                <div className="bg-slate-500 w-1 bottom-0 h-full rounded-full"></div>
              }
              handleBefore={
                <div className="bg-slate-500 w-1 bottom-0 h-full rounded-full"></div>
              }
              aspectRatio={16 / 9}
              onlyHandleDraggable
              handle={({ props }) => {
                return (
                  <div className="bg-slate-200 text-slate-500 h-10 w-10 rounded-full flex items-center justify-center">
                    <BiMoveHorizontal size={24} />
                  </div>
                );
              }}
            /> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Compare;
