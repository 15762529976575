import React from "react";

import Car from "../../images/Car.webp";
// import Button from "../../components/Button";
import DownloadTestflight from "../../images/Download on TestFlight.webp"
import DownloadAppStore from "../../images/download_appstore.webp"

export const LastCTA = () => {
  return (
    <div>
      <section className="">
        <div className="container mx-auto px-4 md:px-10 lg:px-32 flex justify-center items-center content-center py-20">
          <div className="bg-last-cta bg-no-repeat bg-cover bg-bottom rounded-3xl grid lg:grid-cols-2 grid-cols-1 items-center gap-10 py-20 px-10 content-center justify-center text-center">
            <div className="flex items-center justify-center">
              <img src={Car} alt="" className="md:w-[80%] w-full" />
            </div>
            <div className="text-left text-white">
              <h2 className="text-2xl pb-4 md:text-4xl font-bold">
                Reduce Your Carbon Emission Right Now 👊
              </h2>
              <p className="text-base leading-[152%] text-slate-300 mb-4">
                Try Tracco to make a small impact and prevent climate change
                starts from now.
              </p>
              {/* <Button
                name="Download App"
                link="https://tinyurl.com/trytraccov2"
              /> */}

              {/* Download on Testlight */}
              <a
                className="flex transition duration-300 hover:scale-105 active:opacity-90"
                href="https://apps.apple.com/id/app/tracco/id6444603368"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="shadow-lg w-[60%] md:w-[40%]"
                  src={DownloadAppStore}
                  alt="Download Tracco on Testflight"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LastCTA;
