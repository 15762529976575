/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import TraccoLogo from "../../images/Tracco Logo.webp";

export const Navbar = () => {
  let [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function hideAndShow() {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShow(true);
      } else {
        setShow(false);
      }
      setLastScrollY(window.scrollY);
    }
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", hideAndShow);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", hideAndShow);
      };
    }
  }, [hideAndShow, lastScrollY]);

  return (
    <div>
      <nav
        className={`bg-[#165A43] fixed top-0 left-0 z-50 w-full transition-all duration-300 ${
          show ? "top-[-100px]" : "top-0"
        }`}
      >
        <div className="container mx-auto px-4 md:px-10 lg:px-32 flex justify-between items-end py-4">
          {/* Mobile Menu */}
          <div className="flex items-center justify-between z-50">
            {/* Logo */}
            <a
              href="/"
              className="flex items-center justify-center"
              rel="noreferrer"
            >
              <img src={TraccoLogo} alt="Tracco Logo" className="w-10" />
              <p className="text-2xl ml-3 text-white font-bold inline-block">
                Tracco
              </p>
            </a>

            {/* Hamburger Menu */}
            <div
              onClick={() => setOpen(!open)}
              className="text-white text-3xl cursor-pointer absolute md:right-10 sm:right-10 right-4 lg:hidden items-center flex justify-center transition-all duration-300 ease-in-out"
            >
              <ion-icon name={open ? "close" : "menu"}></ion-icon>
            </div>
          </div>

          {/* Navigation Link */}
          <div
            className={`flex lg:flex-row flex-col justify-center items-center lg:static absolute left-0 right-0 top-0 bottom-0 z-40 lg:opacity-100 opacity-0 h-[100vh] lg:h-auto transition-all duration-500 ease-in-out ${
              open
                ? "opacity-100 top-0 bg-[#175A43]"
                : "opacity-0 top-[-1500px] bg-none"
            }
            `}
          >
            <ul className="text-white flex lg:flex-row flex-col justify-center items-center gap-10 lg:gap-8">
              <a
                href="#about"
                className="text-2xl lg:text-base hover:opacity-50 transition ease-in-out duration-300 font-medium"
              >
                <li>About</li>
              </a>
              <a
                href="#howtouse"
                className="text-2xl lg:text-base hover:opacity-50 transition ease-in-out duration-300 font-medium"
              >
                <li>How to Use</li>
              </a>
              <a
                href="#features"
                className="text-2xl lg:text-base hover:opacity-50 transition ease-in-out duration-300 font-medium"
              >
                <li>Features</li>
              </a>
              <a
                href="#compare"
                className="text-2xl lg:text-base hover:opacity-50 transition ease-in-out duration-300 font-medium"
              >
                <li>Compare Emission</li>
              </a>

              {/* Button */}
              <a
                href="mailto:hello@tracco.id"
                target="blank"
                rel="noreferrer"
                className="text-2xl lg:text-base transition ease-in-out duration-300 font-medium border-2 lg:py-2 lg:px-6 py-4 px-10 rounded-full hover:bg-white hover:text-[#175A43]  active:ring-2 active:bg-slate-100 active:ring-slate-500 cursor-pointer"
              >
                <li>Contact Us</li>
              </a>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
