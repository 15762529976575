import React from "react";
import P from "./Text/P";

export const HowToUseCard = (props) => {
  return (
    <div>
      <div className="bg-white shadow-lg flex flex-col justify-center content-center items-center py-10 px-6 rounded-2xl">
        <img src={props.img} alt="" className="" />
        <h3 className="lg:text-[32px] leading-[124%] text-2xl font-bold pt-6 pb-2">
          {props.title}
        </h3>
        <P text={props.text} />
      </div>
    </div>
  );
};

export default HowToUseCard;
