/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import Button from "../../components/Button";
import TraccoLogo from "../../images/Tracco Logo.webp";
import TraccoMockup from "../../images/header mockup.webp";
import DownloadTestflight from "../../images/Download on TestFlight.webp"
import DownloadAppStore from "../../images/download_appstore.webp"


import BacktoTopButton from "../../components/BackToTop";
import Divider from "../Divider";

export const Header = () => {
  let [open, setOpen] = useState(false);
  const [fix, setFix] = useState(false);
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  function changeBackground() {
    if (window.scrollY >= 80) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", changeBackground);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function hideAndShow(){
    if (typeof window !== 'undefined') { 
      if (window.scrollY > lastScrollY) {
        setShow(true); 
      } else { 
        setShow(false);  
      }
      setLastScrollY(window.scrollY); 
    }

  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', hideAndShow);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', hideAndShow);
      };
    }
  }, [hideAndShow, lastScrollY]);


  return (
    <div>
      {/* Header */}
      <header
        className="bg-header-img bg-no-repeat bg-cover bg-center bg-fixed"
        id="header"
      >
        <BacktoTopButton />

        {/* Navbar */}
        <nav
          className={`fixed top-0 left-0 z-50 w-full transition-all duration-300 ${
            fix ? "bg-[#165A43]" : "bg-transparent"} ${show ? "top-[-100px]" : "top-0"}
        `}
        >
          <div className="container mx-auto px-4 md:px-10 lg:px-32 flex justify-between items-end py-4">
            {/* Mobile Menu */}
            <div className="flex items-center justify-between z-50">
              {/* Logo */}
              <a
                href="/"
                className="flex items-center justify-center"
                rel="noreferrer"
              >
                <img src={TraccoLogo} alt="Tracco Logo" className="w-10" />
                <p className="text-2xl ml-3 text-white font-bold inline-block">
                  Tracco
                </p>
              </a>

              {/* Hamburger Menu */}
              <div
                onClick={() => setOpen(!open)}
                className="text-white text-3xl cursor-pointer absolute md:right-10 sm:right-10 right-4 lg:hidden items-center flex justify-center transition-all duration-300 ease-in-out"
              >
                <ion-icon name={open ? "close" : "menu"}></ion-icon>
              </div>
            </div>

            {/* Navigation Link */}
            <div
              className={`flex lg:flex-row flex-col justify-center items-center lg:static absolute left-0 right-0 top-0 bottom-0 z-40 lg:opacity-100 opacity-0 h-[100vh] lg:h-auto transition-all duration-500 ease-in-out ${
                open
                  ? "opacity-100 top-0 bg-[#175A43]"
                  : "opacity-0 top-[-1500px] bg-none"
              }
            `}
            >
              <ul className="text-white flex lg:flex-row flex-col justify-center items-center gap-10 lg:gap-8">
                <a
                  href="#about"
                  className="text-2xl lg:text-base hover:opacity-50 transition ease-in-out duration-300 font-medium"
                >
                  <li>About</li>
                </a>
                <a
                  href="#howtouse"
                  className="text-2xl lg:text-base hover:opacity-50 transition ease-in-out duration-300 font-medium"
                >
                  <li>How to Use</li>
                </a>
                <a
                  href="#features"
                  className="text-2xl lg:text-base hover:opacity-50 transition ease-in-out duration-300 font-medium"
                >
                  <li>Features</li>
                </a>
                <a
                  href="#compare"
                  className="text-2xl lg:text-base hover:opacity-50 transition ease-in-out duration-300 font-medium"
                >
                  <li>Compare Emission</li>
                </a>

                {/* Button */}
                <a
                  href="mailto:hello@tracco.id"
                  target="blank"
                  rel="noreferrer"
                  className="text-2xl lg:text-base transition ease-in-out duration-300 font-medium border-2 lg:py-2 lg:px-6 py-4 px-10 rounded-full hover:bg-white hover:text-[#175A43]  active:ring-2 active:bg-slate-100 active:ring-slate-500 cursor-pointer"
                >
                  <li>Contact Us</li>
                </a>
              </ul>
            </div>
          </div>
        </nav>
        {/* Navbar End */}

        <Divider />

        {/* Header Text */}
        <div className="container mx-auto px-4 md:px-10 lg:px-32 flex justify-center items-center content-center flex-col text-white py-20 lg:h-auto">
          <h1 className="xl:text-7xl md:text-[48px] font-bold lg:leading-[120%] md:leading-[124%] text-[32px] leading-[124%] z-10">
            Reduce Your Carbon Emission Through Transportation
          </h1>
          <p className="text-slate-300 md:text-lg text-base pt-4 pb-6 xl:px-[240px] lg:px-[160px] md:px-[120px] z-10">
            Tracco helps you to track your carbon emission real-time and give
            transport recommendation that has low emission & cost
          </p>
          {/* Button */}
          {/* <Button name="Download App" link="https://tinyurl.com/trytraccov2" /> */}

          {/* Download on Testlight */}
          <a className="flex justify-center items-center transition duration-300 hover:scale-105 active:opacity-90" href="https://apps.apple.com/id/app/tracco/id6444603368" target="_blank" rel="noreferrer">
            <img className="shadow-lg md:w-[40%] w-[45%]" src={DownloadAppStore} alt="Download Tracco on Testflight"/>
          </a>
          {/* Image */}
          <img
            src={TraccoMockup}
            alt="Tracco: Reduce Your Carbon Emission Through Transportation Mockup"
            className="pt-10 relative z-10 lg:w-[80%] w-full"
          ></img>
        </div>
      </header>
    </div>
  );
};

export default Header;
