import React from "react";
import Navbar from "../components/Home/Navbar";
import Footer from "../components/Home/Footer";
import P from "../components/Text/P";
import BacktoTopButton from "../components/BackToTop";
import Divider from "../components/Divider";

export const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <BacktoTopButton />
      <Divider />
      <section className="container mx-auto px-4 md:px-10 lg:px-32 flex flex-col justify-center items-start content-center py-20">
        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">
            Privacy Policy
          </h2>
          <P
            text="
            Tracco built the Tracco app as a Free app. This SERVICE is provided
            by Tracco at no cost and is intended for use as is. This page is
            used to inform visitors regarding our policies with the collection,
            use, and disclosure of Personal Information if anyone decided to use
            our Service. If you choose to use our Service, then you agree to the
            collection and use of information in relation to this policy. The
            Personal Information that we collect is used for providing and
            improving the Service. We will not use or share your information
            with anyone except as described in this Privacy Policy. The terms
            used in this Privacy Policy have the same meanings as in our Terms
            and Conditions, which are accessible at Tracco unless otherwise
            defined in this Privacy Policy."
          />
        </div>
        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">
            Information Collection and Use
          </h2>
          <P
            text="
            For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Email, User ID. The information that we request will be retained by us and used as described in this privacy policy.

            "
          />
        </div>

        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">Log Data</h2>
          <P
            text="
            We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics."
          />
        </div>

        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">Cookies</h2>
          <P
            text="
            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service."
          />
        </div>

        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">
            Service Providers
          </h2>
          <P
            text="
            We may employ third-party companies and individuals due to the following reasons:"
          />
          <ul className="list-disc my-3">
            <li className="ml-5">To facilitate our Service;</li>
            <li className="ml-5">To provide the Service on our behalf;</li>
            <li className="ml-5">To perform Service-related services; or</li>
            <li className="ml-5">
              To assist us in analyzing how our Service is used.
            </li>
          </ul>
          <P
            text="
            We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose."
          />
        </div>

        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">Security</h2>
          <P
            text="
            We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security."
          />
        </div>

        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">
            Links to Other Sites
          </h2>
          <P
            text="
            This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services."
          />
        </div>

        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">
            Children’s Privacy
          </h2>
          <P
            text="
            We do not knowingly collect personally identifiable information from children. We encourage all children to never submit any personally identifiable information through the Application and/or Services. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide personally identifiable information through the Application and/or Services without their permission. If you have reason to believe that a child has provided personally identifiable information to us through the Application and/or Services, please contact us. You must also be at least 16 years of age to consent to the processing of your personally identifiable information in your country (in some countries we may allow your parent or guardian to do so on your behalf)."
          />
        </div>

        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">
            Changes to This Privacy Policy
          </h2>
          <P
            text="
            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 2022-11-21"
          />
        </div>

        <div className="mb-10 text-left">
          <h2 className="md:text-4xl text-2xl font-bold mb-4">Contact Us</h2>
          <p className="text-base leading-[152%] text-slate-800">
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at{" "}
            <a
              href="mailto:hello@tracco.id"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              hello@tracco.id
            </a>{" "}
          </p>{" "}
          <p className="text-base leading-[152%] text-slate-800 mt-3">
            This privacy policy page was created at{" "}
            <a
              href="https://privacypolicytemplate.net"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              privacypolicytemplate.net{" "}
            </a>
            and modified/ generated by{" "}
            <a
              href="https://app-privacy-policy-generator.nisrulz.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              App Privacy Policy Generator
            </a>
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
