import React from "react";
import AboutImg from "../../images/about tracco img kotak-compressed.gif";

export const AboutUs = () => {
  return (
    <div>
      <section className="" id="about">
        <div className="container mx-auto px-4 md:px-10 lg:px-32 flex flex-col lg:flex-row justify-center items-center content-center py-20">
          <div className="w-full flex justify-center items-center">
            <img src={AboutImg} alt="Tracco About" className="" />
          </div>
          <div className="lg:pl-10 lg:pt-0 pt-4">
            <h2 className="text-2xl md:pb-4 pb-2 md:text-4xl font-bold text-left">
              About Tracco 🌱
            </h2>
            <p className="text-base leading-[152%] text-left text-slate-800">
              Tracco is an application helps you reduce your carbon emission
              through transportation by calculating the emission and giving
              recommendations to other transportation modes that are far more
              efficient in terms of carbon emission and cost.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
