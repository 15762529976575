import React from "react";
import CardImage1 from "../../images/onboarding 1.webp";
import CardImage2 from "../../images/onboarding 2.webp";
import CardImage3 from "../../images/onboarding 3.webp";
import HowToUseCard from "../../components/HowToUseCard";
import P from "../Text/P";

export const HowToUse = () => {
  return (
    <div>
      <section className="bg-[#F5F6F6]" id="howtouse">
        <div className="container mx-auto px-4 md:px-10 lg:px-32 flex flex-auto justify-center items-center content-center flex-col py-20">
          {/* Title */}
          <div className="pb-6">
            <h2 className="text-2xl pb-2 md:text-4xl font-bold text-center">
              How to Use Tracco?
            </h2>
            <P text="Just follow these steps to track your emissions" />
          </div>

          {/* Card */}
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-10 lg:gap-y-0 gap-y-6">
            <HowToUseCard
              img={CardImage1}
              title="Choose Your Transportation"
              text="Choose what kind of transportations you take for each transit you use"
            />
            <HowToUseCard
              img={CardImage2}
              title="Track Your Emmission and Cost"
              text="Input your transportations and we’ll count the estimated cost & carbon emmission"
            />
            <HowToUseCard
              img={CardImage3}
              title="Do Your Part to Reduce Emissions"
              text="Challenge yourself to reduce your emission and earn badges to share with your friends!"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowToUse;
