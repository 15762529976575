/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TraccoLogo from "../../images/Tracco Logo.webp";

// Icon
import Instagram from "../../images/icon/instagram.svg";
import Linkedin from "../../images/icon/linkedin.svg";
import Youtube from "../../images/icon/youtube.svg";

export const Footer = () => {
  return (
    <div>
      <footer className="bg-[#F4F4F5]">
        <div className="container mx-auto px-4 md:px-10 lg:px-32 flex flex-col lg:flex-row gap-10 justify-between items-start content-center py-10 transition duration-300 ease-in-out">
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 xl:gap-48 lg:gap-40 items-start"> */}
            <a
              href="#"
              className="flex items-center justify-center"
              rel="noreferrer"
            >
              <img src={TraccoLogo} alt="Tracco Logo" className="w-10" />
              <p className="text-2xl ml-3 text-[#165A43] font-bold inline-block">
                Tracco
              </p>
            </a>

            <div className="grid grid-cols-1 gap-2">
              <p className="uppercase font-bold text-base text-left tracking-wide">
                Company
              </p>
              <a
                href="#about"
                className="text-base text-left text-slate-700 transition duration-300 ease-in-out hover:opacity-50"
              >
                About
              </a>
              <a
                href="#howtouse"
                className="text-base text-left text-slate-700 transition duration-300 ease-in-out hover:opacity-50"
              >
                How to Use
              </a>
              <a
                href="#features"
                className="text-base text-left text-slate-700 transition duration-300 ease-in-out hover:opacity-50"
              >
                Features
              </a>
              <a
                href="#compare"
                className="text-base text-left text-slate-700 transition duration-300 ease-in-out hover:opacity-50"
              >
                Compare Emission
              </a>
              <a
                href="/privacy-policy"
                className="text-base text-left text-slate-700 transition duration-300 ease-in-out hover:opacity-50" target="_blank" rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>

            <div className="grid grid-cols-1 gap-2">
              <p className="uppercase font-bold text-base text-left tracking-wide">
                Contact Us
              </p>
              <a
                href="mailto:hello@tracco.id"
                target="_blank"
                className="text-base text-left text-slate-700 transition duration-300 ease-in-out hover:opacity-50"
                rel="noreferrer"
              >
                hello@tracco.id
              </a>
            </div>

            <div className="grid grid-cols-1 gap-2">
              <p className="uppercase font-bold text-base text-left tracking-wide">
                Follow Us
              </p>
              <div className="flex flex-row gap-4">
                <a
                  href="https://www.instagram.com/traccoapp"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-full bg-[#F7FDFB] p-2 duration-300 ease-in-out hover:opacity-50"
                >
                  <img src={Instagram} alt="Tracco Instagram @traccoapp" />
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-full bg-[#F7FDFB] p-2 duration-300 ease-in-out hover:opacity-50"
                >
                  <img src={Linkedin} alt="Tracco Linkedin" />
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-full bg-[#F7FDFB] p-2 duration-300 ease-in-out hover:opacity-50"
                >
                  <img src={Youtube} alt="Tracco Youtube" />
                </a>
              </div>
            </div>
          {/* </div> */}
        </div>
          <p className="lg:text-center text-left text-base mx-auto px-4 md:px-10 lg:px-32 pb-10 text-slate-700">
            Copyright © 2022 Tracco. All rights reserved. Made with 💚 by
            Tracco.
          </p>
      </footer>
    </div>
  );
};

export default Footer;
