import React from "react";

const P = (props) => {
  return (
    <div>
      <p className="text-base leading-[152%] text-slate-800">
        {props.text}
      </p>
    </div>
  );
};

export default P;
